'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:TenantManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'TenantManager', [
    'Restangular'
    'MundoDefaultManager'
    'DeviceTypeManager'
    'AssetTypesManager'
    'ContactTypeManager'
    'ReportSourceManager'
    'UserRoleManager'
    '$translate'
    '$rootScope'
    (
      Restangular
      MundoDefaultManager
      DeviceTypeManager
      AssetTypesManager
      ContactTypeManager
      ReportSourceManager
      UserRoleManager
      $translate
      $rootScope
    ) ->
      TenantManagerBase = new MundoDefaultManager()

      TenantManagerBase.setUrl('tenants')
      TenantManagerBase.setNewObject([
        'label',
        'code',
        'availableDeviceTypes',
        'availableDeviceTypeProfiles',
        'availableAssetTypes',
        'availableContactTypes',
        'availableReportSources',
        'SAML2IdentityProviderMetadataURL',
        'SAML2IdentityProviderMetadataContent',
        'copiedRoles'
      ])
      TenantManagerBase.setUpdateObject([
        'label',
        'availableDeviceTypes',
        'availableDeviceTypeProfiles',
        'availableAssetTypes',
        'availableContactTypes',
        'availableReportSources',
        'SAML2IdentityProviderMetadataURL',
        'SAML2IdentityProviderMetadataContent'
      ])
      TenantManagerBase.setSearchFields(['label', 'code'])

      TenantManagerBase.getNewForm = ->
        [
            key: 'label'
            name: 'label'
            type: 'input'
            templateOptions:
              label: 'Label'
              placeholder: 'label'
              required: true
              description: 'Descriptive text'
          ,
            key: 'code'
            name: 'code'
            type: 'input'
            templateOptions:
              label: 'Code'
              placeholder: 'code'
              required: true
              description: 'Descriptive text'
          ,
            key: 'availableDeviceTypes'
            name: 'availableDeviceTypes'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.devices.deviceTypes'
              placeholder: 'availableDeviceTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.devices.deviceTypes'
              valueProp: 'code'
              options: TenantManagerBase.deviceTypes
          ,
            key: 'availableDeviceTypeProfiles'
            name: 'availableDeviceTypeProfiles'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.devices.deviceTypeProfiles'
              placeholder: 'availableDeviceTypeProfiles'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.devices.deviceTypeProfiles'
              valueProp: 'code'
              options: TenantManagerBase.deviceProfiles
          ,
            key: 'availableAssetTypes'
            name: 'availableAssetTypes'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.assets.assetTypes'
              placeholder: 'availableAssetTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.assets.assetTypes'
              valueProp: 'code'
              options: TenantManagerBase.assetTypes
          ,
            key: 'availableContactTypes'
            name: 'availableContactTypes'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.contacts.contactTypes'
              placeholder: 'availableContactTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.contacts.contactTypes'
              valueProp: 'code'
              options: TenantManagerBase.contactTypes
          ,
            key: 'availableReportSources'
            name: 'availableReportSources'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.reports.reportSources'
              placeholder: 'availableReportSources'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.reports.reportSources'
              valueProp: 'code'
              options: TenantManagerBase.reportSources
          ,
            key: 'copiedRoles'
            name: 'copiedRoles'
            type: 'modelchips'
            templateOptions:
              label: $translate.instant 'app.admin.users.userRoles'
              placeholder: 'copiedRoles'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.users.userRoles'
              valueProp: 'id'
              options: UserRoleManager.getFullList().$object
          ,
            key: 'SAML2IdentityProviderMetadataURL'
            name: 'SAML2IdentityProviderMetadataURL'
            type: 'input'
            templateOptions:
              label: $translate.instant 'app.admin.users.SAML2IdentityProviderMetadataURL'
              required: false
          ,
            key: 'SAML2IdentityProviderMetadataContent'
            name: 'SAML2IdentityProviderMetadataContent'
            type: 'textarea'
            templateOptions:
              label: $translate.instant 'app.admin.users.SAML2IdentityProviderMetadataContent'
              required: false
              rows: 5
        ]

      TenantManagerBase.getEditForm = (data)->
        [
            key: 'label'
            name: 'label'
            type: 'input'
            defaultValue: data.label
            templateOptions:
              label: 'Label'
              placeholder: 'johndoe'
              required: true
              description: 'Descriptive text'
          ,
            key: 'availableDeviceTypes'
            name: 'availableDeviceTypes'
            type: 'modelchips'
            defaultValue: (x for x in TenantManagerBase.deviceTypes when x.code in data.availableDeviceTypes)
            templateOptions:
              label: $translate.instant 'app.admin.devices.deviceTypes'
              placeholder: 'availableDeviceTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.devices.deviceTypes'
              valueProp: 'code'
              options: TenantManagerBase.deviceTypes
          ,
            key: 'availableDeviceTypeProfiles'
            name: 'availableDeviceTypeProfiles'
            type: 'modelchips'
            defaultValue: (x for x in TenantManagerBase.deviceProfiles when x.code in data.availableDeviceTypeProfiles)
            templateOptions:
              label: $translate.instant 'app.admin.devices.deviceTypeProfiles'
              placeholder: 'availableDeviceTypeProfiles'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.devices.deviceTypeProfiles'
              valueProp: 'code'
              options: TenantManagerBase.deviceProfiles
          ,
            key: 'availableAssetTypes'
            name: 'availableAssetTypes'
            type: 'modelchips'
            defaultValue: (x for x in TenantManagerBase.assetTypes when x.code in data.availableAssetTypes)
            templateOptions:
              label: $translate.instant 'app.admin.assets.assetTypes'
              placeholder: 'availableAssetTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.assets.assetTypes'
              valueProp: 'code'
              options: TenantManagerBase.assetTypes
          ,
            key: 'availableContactTypes'
            name: 'availableContactTypes'
            type: 'modelchips'
            defaultValue: (x for x in TenantManagerBase.contactTypes when x.code in data.availableContactTypes)
            templateOptions:
              label: $translate.instant 'app.admin.contacts.contactTypes'
              placeholder: 'availableContactTypes'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.contacts.contactTypes'
              valueProp: 'code'
              options: TenantManagerBase.contactTypes
          ,
            key: 'availableReportSources'
            name: 'availableReportSources'
            type: 'modelchips'
            defaultValue: (x for x in TenantManagerBase.reportSources when x.code in data.availableReportSources)
            templateOptions:
              label: $translate.instant 'app.admin.reports.reportSources'
              placeholder: 'availableReportSources'
              multiple: true,
              labelProp: 'label'
              autocompleteLabel : $translate.instant 'app.admin.reports.reportSources'
              valueProp: 'code'
              options: TenantManagerBase.reportSources
          ,
            key: 'SAML2IdentityProviderMetadataURL'
            name: 'SAML2IdentityProviderMetadataURL'
            type: 'input'
            defaultValue: data.SAML2IdentityProviderMetadataURL
            templateOptions:
              label: $translate.instant 'app.admin.users.SAML2IdentityProviderMetadataURL'
              required: false
          ,
            key: 'SAML2IdentityProviderMetadataContent'
            name: 'SAML2IdentityProviderMetadataContent'
            type: 'textarea'
            defaultValue: data.SAML2IdentityProviderMetadataContent
            templateOptions:
              label: $translate.instant 'app.admin.users.SAML2IdentityProviderMetadataContent'
              required: false
              rows: 5
        ]

      TenantManagerBase.getListSchema = ->
        [
          (
            key: 'label'
            title: 'datatable.label.label'
            sort: 'label'
          )
          (
            key: 'code'
            title: 'datatable.label.code'
            sort: 'code'
          )
          (
            key: 'tenantChildren.label'
            title: 'datatable.label.children'
            multiple: 'tenantChildren'
            hideInList: true
          )
        ]

      #permission needed to edit/delete an entity
      TenantManagerBase.editPermission = 'manage all MundoMosaUserBundle:Tenant entities'

      TenantManagerBase.new = (data) ->
        data = angular.copy data

        if data.availableDeviceTypes
          data.availableDeviceTypes = (x.code for x in angular.copy data.availableDeviceTypes)

        if data.availableDeviceTypeProfiles
          data.availableDeviceTypeProfiles = (x.code for x in angular.copy data.availableDeviceTypeProfiles)

        if data.availableAssetTypes
          data.availableAssetTypes = (x.code for x in angular.copy data.availableAssetTypes)

        if data.availableContactTypes
          data.availableContactTypes = (x.code for x in angular.copy data.availableContactTypes)

        if data.availableReportSources
          data.availableReportSources = (x.code for x in angular.copy data.availableReportSources)

        if data.copiedRoles
          data.copiedRoles = (x.id for x in angular.copy data.copiedRoles)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()))

      TenantManagerBase.update = (id, data) ->
        if data.availableDeviceTypes
          data.availableDeviceTypes = (x.code for x in angular.copy data.availableDeviceTypes)

        if data.availableDeviceTypeProfiles
          data.availableDeviceTypeProfiles = (x.code for x in angular.copy data.availableDeviceTypeProfiles)

        if data.availableAssetTypes
          data.availableAssetTypes = (x.code for x in angular.copy data.availableAssetTypes)

        if data.availableContactTypes
          data.availableContactTypes = (x.code for x in angular.copy data.availableContactTypes)

        if data.availableReportSources
          data.availableReportSources = (x.code for x in angular.copy data.availableReportSources)

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      #filter out current tenant and grandchildren of tenant
      TenantManagerBase.getList = (query, params) ->
        TenantManagerBase.deviceTypes ?= DeviceTypeManager.getListCustom()
        TenantManagerBase.deviceProfiles ?= DeviceTypeManager.getProfileList()
        TenantManagerBase.assetTypes ?= AssetTypesManager.getListCustom()

        if not TenantManagerBase.reportSources?
          ReportSourceManager.getListCustom().then (res) ->
            TenantManagerBase.reportSources = res

        if not TenantManagerBase.contactTypes?
          ContactTypeManager.getList().then (res) ->
            TenantManagerBase.contactTypes = res.plain()

        loadParams = {}
        if query.sort
          if query.sort.substr(0,1) == "-"
            loadParams.sort = query.sort.substr(1) + ',DESC'
          else
            loadParams.sort = query.sort.substr(0) + ',ASC'

        if query.search && query.search.active
          searchString = ''
          searchFields =  @getSearchFields()
          _.each searchFields, (field) ->
            searchString = "#{searchString},#{field},like #{query.search.query}"

          loadParams['filter[]'] = "OR#{searchString}"

        TenantManagerBase.getFullList(loadParams).then((response)->
          while !$rootScope.user
            null
          current = $rootScope.user.activeUserContext.tenant
          childrenOfCurrent = _.filter response, ((obj) ->
            if obj.parentTenant? then obj.parentTenant.id == current.id else false)

          for child in childrenOfCurrent
            child.tenantChildren = []
            for tenant in response
              if tenant.parentTenant? and tenant.parentTenant.id == child.id
                child.tenantChildren.push tenant

          limit = if query.limit? then query.limit else 10
          offset = if query.page? then limit * (query.page - 1) else 0

          count = childrenOfCurrent.length

          childrenOfCurrent = childrenOfCurrent.splice(offset, limit)
          childrenOfCurrent.count = count

          return childrenOfCurrent
          )

      #permission needed to edit/delete an entity
      TenantManagerBase.editPermission = 'manage all MundoMosaUserBundle:Tenant entities'

      TenantManagerBase
  ]
